<template>
  <div class="registry-sign-up-form">
    <h5>Fill Information for Registry Signup</h5>
    <form>
      <div class="registry-sign-up-form-content">
        <div class="personal-info-wrapper">
          <h6>Personal Information</h6>
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              placeholder="Enter First Name"
              autocomplete="off"
              v-model.trim="$v.details.first_name.$model"
              :class="{
                'is-invalid': $v.details.first_name.$error,
                'is-valid': !$v.details.first_name.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.first_name.required"
                >First name is required</span
              >
              <span v-if="!$v.details.first_name.minLength"
                >First name must have at least
                {{ $v.details.first_name.$params.minLength.min }}</span
              >
              <span v-if="!$v.details.first_name.maxLength"
                >First name must have at most
                {{ $v.details.first_name.$params.maxLength.max }}</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              placeholder="Enter Last Name"
              autocomplete="off"
              v-model.trim="$v.details.last_name.$model"
              :class="{
                'is-invalid': $v.details.last_name.$error,
                'is-valid': !$v.details.last_name.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.last_name.required"
                >Last name is required</span
              >
              <span v-if="!$v.details.last_name.minLength"
                >Last name must have at least
                {{ $v.details.last_name.$params.minLength.min }}</span
              >
              <span v-if="!$v.details.last_name.maxLength"
                >Last name must have at most
                {{ $v.details.last_name.$params.maxLength.max }}</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Enter Email Address"
              autocomplete="off"
              v-model.trim="$v.details.email.$model"
              :class="{
                'is-invalid': $v.details.email.$error,
                'is-valid': !$v.details.email.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.email.required">Email is required</span>
              <span v-if="!$v.details.email.email">Email must be valid </span>
            </div>
          </div>
          <h6>Legal Contant Information</h6>
          <div class="form-group">
            <label for="contactName">Legal or Compliance Contact Name</label>
            <input
              type="text"
              v-model.trim="$v.details.legal_contact_name.$model"
              class="form-control"
              id="contactName"
              placeholder="Enter Name"
              :class="{
                'is-invalid': $v.details.legal_contact_name.$error,
                'is-valid': !$v.details.legal_contact_name.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.legal_contact_name.required"
                >Contact Name is required</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="contactEmail">Legal or Compliance Contact Email</label>
            <input
              type="email"
              v-model.trim="$v.details.legal_contact_email.$model"
              class="form-control"
              id="contactEmail"
              placeholder="Enter Email"
              :class="{
                'is-invalid': $v.details.legal_contact_email.$error,
                'is-valid': !$v.details.legal_contact_email.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.legal_contact_email.required"
                >Contact Email is required</span
              >
              <span v-if="!$v.details.legal_contact_email.email"
                >Email must be valid
              </span>
            </div>
          </div>
          <div
            class="form-group d-flex align-items-center justify-content-start"
          >
            <input
              type="checkbox"
              class="mr-2"
              id="exampleCheck1"
              @change="showHideError()"
            />
            <label class="form-check-label" for="exampleCheck1"
              >I Agree to terms and conditions and Privacy Policy</label
            >
          </div>
          <p class="custom-error">Please check terms and conditions checkbox</p>
        </div>
        <div class="company-info-wrapper">
          <h6>Company Information</h6>
          <div class="user-profile-image">
            <div class="profile-image mx-auto">
              <div id="preview" class="profile-image">
                <img v-if="url" :src="url" />
                <img v-else src="../../assets/images/company-logo.png" alt="" />
              </div>
            </div>

            <div
              class="change-profile-image justify-content-center"
              @click="openinput()"
            >
              <form>
                <input
                  type="file"
                  id="vue-file-upload-input-profile-photo"
                  @change="onFileChange"
                  class="d-none"
                  accept="image/png, image/gif, image/jpeg"
                />

                <div class="change-icon">
                  <img src="../../assets/images/pencil.png" alt="" />
                  <img
                    src="../../assets/images/pencil-underline.png"
                    alt=""
                    class="pencil-underline"
                  />
                  <span class="text">Change Company Logo</span>
                </div>
              </form>
            </div>
          </div>
          <div class="form-group">
            <label for="companyName">Company Name</label>
            <input
              type="text"
              class="form-control"
              id="companyName"
              placeholder="Company Name"
              autocomplete="off"
              v-model.trim="$v.details.company_name.$model"
              :class="{
                'is-invalid': $v.details.company_name.$error,
                'is-valid': !$v.details.company_name.$invalid,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.company_name.required"
                >Company name is required</span
              >
              <span v-if="!$v.details.company_name.minLength"
                >Comapany name must have at least
                {{ $v.details.company_name.$params.minLength.min }}</span
              >
              <span v-if="!$v.details.company_name.maxLength"
                >Company name must have at most
                {{ $v.details.company_name.$params.maxLength.max }}</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="companyWebsite">Company Website</label>
            <input
              type="text"
              v-model.trim="$v.details.company_website.$model"
              class="form-control"
              id="companyWebsite"
              placeholder="Company Website"
              :class="{
                'is-invalid': $v.details.company_website.$error,
                'is-valid': !$v.details.company_website.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.company_website.required"
                >Company website is required</span>
              <span v-if="!$v.details.company_website.url"
                >URL must be valid</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="companyAddress">Company Address</label>
            <input
              type="text"
              v-model.trim="$v.details.company_address.$model"
              class="form-control"
              id="companyAddress"
              placeholder="Company Address"
              :class="{
                'is-invalid': $v.details.company_address.$error,
                'is-valid': !$v.details.company_address.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.company_address.required"
                >Company address is required</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="companySize">Company Size</label>
            <input
              type="text"
              v-model.trim="$v.details.company_size.$model"
              class="form-control"
              id="companySize"
              placeholder="Company Size"
              :class="{
                'is-invalid': $v.details.company_size.$error,
                'is-valid': !$v.details.company_size.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.company_size.required"
                >Company size is required</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="line-seperator"></div>
      <div class="registry-sign-up-form-content">
        <div class="personal-info-wrapper">
          <div class="form-group">
            <label for="contactName">Legal or Compliance Contact Name</label>
            <input
              type="text"
              v-model.trim="$v.details.legal_contact_name.$model"
              class="form-control"
              id="contactName"
              placeholder="Enter Name"
              :class="{
                'is-invalid': $v.details.legal_contact_name.$error,
                'is-valid': !$v.details.legal_contact_name.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.legal_contact_name.required"
                >Contact Name is required</span
              >
            </div>
          </div>
          <div class="form-group">
            <label for="contactEmail">Legal or Compliance Contact Email</label>
            <input
              type="email"
              v-model.trim="$v.details.legal_contact_email.$model"
              class="form-control"
              id="contactEmail"
              placeholder="Enter Email"
              :class="{
                'is-invalid': $v.details.legal_contact_email.$error,
                'is-valid': !$v.details.legal_contact_email.$invalid,
              }"
              autocomplete="off"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.details.legal_contact_email.required"
                >Contact Email is required</span
              >
              <span v-if="!$v.details.legal_contact_email.email"
                >Email must be valid
              </span>
            </div>
          </div>
          <div
            class="form-group d-flex align-items-center justify-content-start"
          >
            <input
              type="checkbox"
              class="mr-2"
              id="exampleCheck1"
              @change="showHideError()"
            />
            <label class="form-check-label" for="exampleCheck1"
              >I Agree to terms and conditions and Privacy Policy</label
            >
          </div>
          <p class="custom-error">Please check terms and conditions checkbox</p>
        </div>
      </div> -->
      <button
        type="button"
        class="custom-btn2"
        @click="signUp(0)"
        :disabled="isLoadingArray[0]"
      >
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Confirm</span>
      </button>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  url,
} from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "RegistryForm",
  data: function() {
    return {
      details: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        legal_contact_email: "",
        legal_contact_name: "",
        company_address: "",
        company_website: "",
        company_size: "",
        company_logo: null,
      },
      isLoadingArray: [],
      time: this.$time,
      API_URL: this.$userProfileStorageURL,
      url: null,
      profilePhoto: null,
      oldPicture: true,
    };
  },
  validations: {
    details: {
      first_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      last_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      email: {
        required,
        email,
      },
      company_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25),
      },
      legal_contact_email: {
        required,
        email,
      },
      legal_contact_name: {
        required,
      },
      company_address: {
        required,
      },
      company_size: { required },
      company_website: {
        required,
        url,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    showHideError: function() {
      if (document.getElementById("exampleCheck1").checked) {
        document.querySelector(".custom-error").style.display = "none";
      } else {
        document.querySelector(".custom-error").style.display = "block";
      }
    },
    onFileChange(e) {
      this.oldPicture = false;
      this.details.company_logo = e.target.files[0];
      this.url = URL.createObjectURL(this.details.company_logo);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    signUp: function(index) {
      
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      if (document.getElementById("exampleCheck1").checked) {
        document.querySelector(".custom-error").style.display = "none";
      } else {
        document.querySelector(".custom-error").style.display = "block";
        return;
      }
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("first_name", this.details.first_name);
      formData.append("email", this.details.email);
      formData.append("last_name", this.details.last_name);
      formData.append("company_name", this.details.company_name);
      formData.append("legal_contact_email", this.details.legal_contact_email);
      formData.append("legal_contact_name", this.details.legal_contact_name);
      formData.append("company_address", this.details.company_address);
      formData.append("company_logo", this.details.company_logo);
      formData.append("company_size", this.details.company_size);
      formData.append("company_website", this.details.company_website);
      axios
        .post(process.env.VUE_APP_API_URL + "registry/create", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            document.querySelector(".custom-error").style.display = "none";
            setTimeout(() => {
              this.$root.$emit("dataRegistry", response.data.data);
            }, 500);

            this.$router.push({ name: "GlobalNDAForm" });
            this.$set(this.isLoadingArray, index, false);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
  },
};
</script>
