<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <RegistryForm />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import RegistryForm from "../../components/Enterprise/registrysignup/RegistryForm.vue";

export default {
  name: "EnterpriseRegistrySignupPage",

  components: {
    Navbar,
    LeftMenu,
    RegistryForm,
  },
};
</script>
