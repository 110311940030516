<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <RegistryForm />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/header/Navbar.vue";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import RegistryForm from "../components/registrysignup/RegistryForm.vue";

export default {
  name: "RegistrySignupPage",

  components: {
    Navbar,
    LeftMenu,
    RegistryForm,
  },
};
</script>
